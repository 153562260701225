import VueRouter, { Route } from 'vue-router';
import { startsWith } from 'lodash';

export function formatUrl(url?: string): string {
  return '/' + (url && url.replace(/^\//, '').replace(/\/$/, '').replace(/\?.*?$/, '')) || '';
}

export function navigate(path: string, route: Route, router: VueRouter): void {
  const targetPath = formatUrl(path);
  if (!startsWith(route.path, targetPath) || route.path !== '/' && path === '') {
    router.push(formatUrl(path));
  }
}

const hexDigits = '0123456789abcdef';

export function uuid(): string {
  const s: any[] = [];
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4';  // bits 12-15 of the time_hi_and_version field to 0010

  // tslint:disable-next-line: no-bitwise
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';
  return s.join('');
}

//格式化金额
export function getMoney(data: string) {
  if (!data) return '0';
  // 将数据分割，保留两位小数
  //data= data.toFixed(2)
  // 获取整数部分
  const intPart = Math.trunc(Number(data));
  // 整数部分处理，增加,
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  // 预定义小数部分
  let floatPart = '';
  // 将数据分割为小数部分和整数部分
  const newArr = data.toString().split('.');
  if (newArr.length === 2) { // 有小数部分
    floatPart = newArr[1].toString(); // 取得小数部分
    return intPartFormat + '.' + floatPart;
  }

  return intPartFormat + floatPart;
}
