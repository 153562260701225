





































import { Component, Vue, Prop } from 'vue-property-decorator';
import { MenuItem } from '@/services/common/models/menu.interface';
import { formatUrl, navigate } from '@/services/utils';

@Component({
  name: 'Menu'
})
export default class Menu extends Vue {
  @Prop() public menu!: MenuItem;
  @Prop() public roleId!: string;
  private formatUrl: (url?: string) => string = formatUrl;

  public navigate(path: string): void {
    navigate(path, this.$route, this.$router);
  }
}
