

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class NovaPagination extends Vue {
  // 每页显示个数选择器的选项设置 number[]
  @Prop({ default: () => [10, 20, 50, 80, 100, 150] })
  private pageSizes!: number[];
  // 总条目数 number
  @Prop({ type: Number, default: 1 })
  private total!: number;
  // 当前页数，支持 .sync 修饰符
  @Prop({ type: Number, default: 1 })
  private pageNum!: number;
  // 每页显示条目个数，支持 .sync 修饰符
  @Prop({ type: Number, default: 1 })
  private pageSize!: number;

  // 当前页数发生改变时触发
  private handleCurrentChange(pageNum: number) {
    this.$emit(
      'pageChange',

      { pageNum, pageSize: this.pageSize },
      'pagination'
    );
  }

  // pageSize(每页显示条目数) 改变时会触发@size-change
  private handleSizeChange(pageSize: number) {
    this.$emit('pageChange', { pageNum: 1, pageSize }, 'pagination');
  }
}
