import NovaContainer from './NovaContainer/index';
import SearchTable from './SearchTable/index';
import NovaTable from './NovaTable/index';
import NovaPagination from './NovaPagination';

const KeysObje: Dict<any> = {
  NovaContainer,
  SearchTable,
  NovaTable,
  NovaPagination
};

export default KeysObje;
