
















































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CacheService, Inject } from '@cds/common';
import { MenuService, MENU } from '@/services/common/menu.service';
import { UserService } from '@/services/user-service';
import {
  MenuItem,
  BreadcrumbItem,
  MenuType
} from '@/services/common/models/menu.interface';
import { find, isEmpty, isUndefined } from 'lodash';
import { Route } from 'vue-router';
import Menu from './menu.vue';
// import MenuAside from './menuaside.vue';
import { formatUrl } from '@/services/utils';
import { I18nService, Locale } from '@cds/i18n';

const isSideMenuCollapse = 'isSideMenuCollapse';

@Component({
  components: {
    Menu
    // MenuAside
  }
})
export default class Layout extends Vue {

  public currentLanguage: string = '';
  public isCollapse: boolean = false;
  public userselfMenu: boolean = false;//手动管理菜单
  public userselfMenuName: string = '';//手动管理菜单名称
  public activeIndex!: string;
  public langOptions: { localId: string; label: string }[] = [
    {
      localId: 'zh-CN',
      label: '中文简体'
    },
    {
      localId: 'en-GB',
      label: 'English'
    }
  ];

  @Prop({
    type: String,
    required: true
  })
  private appName!: string;
  @Inject(MenuService) private menuSvc!: MenuService;
  @Inject(CacheService) private cacheSvc!: CacheService;
    @Inject(CacheService) private cache!: CacheService;
  @Inject(UserService) private userService!: UserService;
  private userInfo: any = this.cacheSvc.get('user');
  private navigation: MenuItem[] = [];
  private breadcrumbItems: BreadcrumbItem[] = [];
  private targetMenu: MenuItem = {};
  private formatUrl: (url?: string) => string = formatUrl;
  private changeIsCollapse!: (message: Dict<any>) => void;

  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  private activeName: string = 'first';

  public created(): void {
    // this.i18nSvc.on(Lifecycle.loaded, () => {
    //   debugger
    // });
    this.activeIndex = '/' + this.formatUrl(this.$route.path).split('/')[1];
    // this.activeIndex = '/';
    this.isCollapse = !!this.cacheSvc.get(isSideMenuCollapse);
    this.getMenuList(this.appName);
    const callback = (message: Dict<any>) => {
      if (message.type === isSideMenuCollapse) {
        this.isCollapse = message.payload;
      }
    };
    this.changeIsCollapse = callback.bind(this);

    this.$router.beforeEach((to: Route, from: Route, next) => {
      if (to.meta && to.meta.title) {
        switch (this.i18nSvc.currentLocaleId) {
          case Locale.zh_CN:
            document.title = to.meta.title;
            break;
          default:
            document.title = to.meta.titleEn;
        }
      }
      // this.activeIndex = '/' + this.formatUrl(to.path).split('/')[1];
      next();
    });
    this.currentLanguage = (
      find(
        this.langOptions,
        (e: any) =>
          e.localId.split('-')[0] === this.i18nSvc.currentLocaleId.split('-')[0]
      ) as any
    ).label;
  }
 //退出登录
 public async loginOut() {
    localStorage.removeItem('Token')
    this.cache.remove('token');
    let endSessionEndpoint = "https://login.microsoftonline.com/common/oauth2/v2.0/logout";
    let redirectUrl =`${location.origin}`;
    let result = await this.userService.logout();
     window.location.href= endSessionEndpoint;

    // window.location.href= endSessionEndpoint + "?post_logout_redirect_uri="+redirectUrl;

  }

  private async getMenuList(appName: string): Promise<void> {
    let menus = this.cacheSvc.get(MENU) as MenuItem[];
    if (!menus) {
      menus = await this.menuSvc.findMenuList();
    }
    const targetMenu = find(
      menus,
      (item: any) => item.menuCode === this.appName
    );
    this.navigation = ((targetMenu && targetMenu.children) || [])
      .filter((e: any) => [MenuType.GROUP, MenuType.ITEM].includes(e.menuType))
      .reverse();
    if (targetMenu) {
      this.targetMenu = {
        id: targetMenu.id,
        menuUrl: this.formatUrl(targetMenu.menuUrl),
        menuName: targetMenu.menuName
      };
      this.routerListenHandler();
    }
  }

  private routerListenHandler(): void {
    this.buildBreadcrumb(this.$route.fullPath, this.navigation);
    this.$router.afterEach((to: Route, from: Route) => {
      this.buildBreadcrumb(to.fullPath, this.navigation);
      this.activeIndex = this.formatUrl(
        to.path.replace(/^\//, '').split('/')[0]
      );
    });
  }

  private buildBreadcrumb(fullPath: string, menuItems: MenuItem[]): void {
    const arr = this.formatUrl(fullPath).split('/');
    this.breadcrumbItems = [];
    arr.shift();
    this.getBreadcrumbs(arr, menuItems, this.breadcrumbItems);
    this.breadcrumbItems = [this.targetMenu].concat(this.breadcrumbItems);
  }

  private getBreadcrumbs(
    paths: string[],
    menuItems: MenuItem[],
    breadcrumbItems: BreadcrumbItem[]
  ): BreadcrumbItem[] {
    if (!isUndefined(paths[0])) {
      const targetMenuItem = this.findTargetItem(paths[0], menuItems);
      if (targetMenuItem.id && targetMenuItem.menuType !== MenuType.GROUP) {
        paths.shift();
        const previous = breadcrumbItems[breadcrumbItems.length - 1] || {
          menuUrl: ''
        };
        breadcrumbItems.push({
          id: targetMenuItem.id,
          menuCode: targetMenuItem.menuCode,
          menuUrl: `${previous.menuUrl}${this.formatUrl(
            targetMenuItem.menuUrl
          )}`,
          menuName: targetMenuItem.menuName
        });
        if (targetMenuItem.children) {
          return this.getBreadcrumbs(
            paths,
            targetMenuItem.children,
            breadcrumbItems
          );
        }
      } else {
        const currentItem = breadcrumbItems[breadcrumbItems.length - 1];
        if (currentItem) {
          currentItem.menuUrl = `${currentItem.menuUrl}${this.formatUrl(
            paths[0]
          )}`;
        }
      }
    }
    return breadcrumbItems;
  }

  private findTargetItem(path: string, menuItems: MenuItem[]): MenuItem {
    if (path == 'creatMessage') {
      this.userselfMenu = true;//手动管理菜单
      this.userselfMenuName = '创建专栏群发消息';//手动管理菜单名称
    }else
    if (path == 'channelMessage') {
      this.userselfMenu = true;//手动管理菜单
      this.userselfMenuName = '创建应用群发消息';//手动管理菜单名称
    }else  if (path == 'greatGroup') {
      this.userselfMenu = true;//手动管理菜单
      this.userselfMenuName = '创建分组';//手动管理菜单名称
    }else  if (path == 'addProductMap.vue') {
      this.userselfMenu = true;//手动管理菜单
      this.userselfMenuName = '创建图文';//手动管理菜单名称
    }else  if (path == 'imageTextClassify') {
      this.userselfMenu = true;//手动管理菜单
      this.userselfMenuName = '图文分类';//手动管理菜单名称
    } else{
       this.userselfMenu = false;//手动管理菜单
        this.userselfMenuName = '';//手动管理菜单名称
    }


    let targetItem;
    for (const index in menuItems) {
      if (menuItems[index]) {
        const item = menuItems[index];
        if (item.menuType === MenuType.GROUP && item.children) {
          targetItem = this.findTargetItem(path, item.children);
        } else if (
          !!item.menuUrl &&
          this.formatUrl(item.menuUrl).replace(/^\//, '') === path
        ) {
          targetItem = item;
        }
        if (!isEmpty(targetItem)) {
          break;
        }
      }
    }
    return targetItem || {};
  }

  // 展开头像菜单
  private changeLanguage(command: string) {
    // 菜单切换
    this.i18nSvc.currentLocaleId = command;
    this.currentLanguage = (
      find(this.langOptions, (e: any) => e.localId === command) as any
    ).label;
    this.i18nSvc.load();
    // this.i18n.currentLocaleId = this.i18n
    window.location.reload();
  }
}
