import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { Notification } from 'element-ui';

export class UserapplicationService {
  @Inject(HttpService) private http!: HttpService;
  
  
  //查询当前用户是否存在其它系统
  public systemList(): Promise<any> {
    return this.http.get('/api/oauth2-client-info/system-list');
  }
}
