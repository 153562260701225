export interface MenuItem {
  createBy?: string;
  createTime?: string;
  delFlg?: string;
  id?: string;
  isParent?: string;
  lastUpdBy?: string;
  lastUpdTime?: string;
  menuCode?: string;
  menuName?: string;
  menuUrl?: string;
  parentId?: string;
  menuIcon?: string;
  sort?: number;
  children?: MenuItem[];
  menuType?: MenuType;
}

export interface BreadcrumbItem {
  id?: string;
  menuCode?: string;
  menuUrl?: string;
  menuName?: string;
}

export enum MenuType {
  GROUP = 'menuGroup',
  ITEM = 'menuItem',
  case = 'item'
}
