import { ElMessageBoxComponent, MessageBoxCloseAction } from 'element-ui/types/message-box';

export interface IDialogOption {
  customClass?: string;
  showClose?: boolean;
  key?: string;
  beforeClose?: (action: MessageBoxCloseAction, instance: ElMessageBoxComponent, done: (() => void)) => void;
  lockScroll?: boolean;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  cancelButtonClass?: string;
  confirmButtonClass?: string;
  center?: boolean;
  roundButton?: boolean;
  closeOnClickModal?: boolean;
  closeOnPressEscape?: boolean;
  closeOnHashChange?: boolean;
  distinguishCancelAndClose?: boolean;
  width?: number | string;
  height?: number | string;
  ctorPrototype?: any;
}

export enum CloseStatus {
  confirm = 'confirm',
  cancel = 'cancel',
  close =  'close'
}
