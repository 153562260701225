import { Inject } from '@cds/common';
import { filter, map } from 'lodash';
import { MenuItem, MenuType } from './models/menu.interface';
import { HttpService } from './http.service';
import { PermissionService } from './permission.service';

export const MENU = 'MENU';
// const menuApi = 'mock-menu-data.json';
export class MenuService {
  @Inject(HttpService) private http!: HttpService;
  @Inject(PermissionService) private permissionSvc!: PermissionService;
  private navigation!: MenuItem[];
  public async findMenuList(): Promise<any> {
    if (!this.navigation || this.navigation.length === 0) {
      const permissions = await this.permissionSvc.findPermissionList();
      this.navigation = filter(
        map(permissions, (e) => {
          return {
            id: e.id,
            menuCode: e.code,
            menuName: e.name,
            menuUrl: e.url,
            menuType: e.type as MenuType,
            menuIcon: e.icon,
            parentId: e.parentId,
            sort: e.sort,
            icon: e.icon
          };
        }),
        (e) => ([MenuType.GROUP, MenuType.ITEM].includes(e.menuType) || (MenuType.case === e.menuType && !!e.menuUrl))
      ).sort((p, n) => (n.sort || 0) - (p.sort || 0));
    }
    const roots = filter(
      this.navigation,
      (e) => MenuType.GROUP === e.menuType && String(e.parentId) === '0'
    );
    for (const index in roots) {
      if (roots[index]) {
        this.buildMenu(roots[index], this.navigation);
      }
    }
    return roots;
  }

  private buildMenu(item: MenuItem, navigation: MenuItem[]): void {
    const childrenItems = filter(
      navigation,
      (e) => String(e.parentId) === String(item.id)
    ).sort((p, n) => (n.sort || 0) - (p.sort || 0));
    for (const index in childrenItems) {
      if (childrenItems[index]) {
        this.buildMenu(childrenItems[index], navigation);
      }
    }
    item.children = childrenItems;
  }
}
