import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class UserService {
  @Inject(HttpService) private http!: HttpService;

  public getUserList(param: Dict<any>): Promise<any> {
    // console.log(':::service --::', param);
    return this.http.get('/api/user/all', param);
  }
  public getUserListProfile(param: Dict<any>): Promise<any> {
    return this.http.get('/api/user/profile', param);
  }

  public getUserPropilePaging(param: Dict<any>): Promise<any> {
    return this.http.get(`/api/user/profile/page/${param.offset}/${param.limit}`, { searchParam: param.searchParam });
  }

  public delete(param: Dict<any>): Promise<any> {
    // console.log(':::service --::', param);
    return this.http.delete('/api/user/' + param);
  }
  public getList(param: Dict<any>): Promise<any> {
    // let params = {
    //   fullName:param.fullName,
    //   roleId:param.roleId
    // };
    // console.log(':::service --::', param);
    return this.http.get(
      '/api/user/page/' + param.offset + '/' + param.limit,
      param
    );
  }

  public doAnync(param: Dict<any>): Promise<any> {
    // let params = {
    //   fullName:param.fullName,
    //   roleId:param.roleId
    // };
    // console.log(':::service --::', param);
    return this.http.post('/api/user/sync/' + param.id);
  }

  public exportExcel(param: Dict<any>): Promise<any> {
    return this.http.downloadFile(
      '/api/user/export',
      param,
      'User Export Doc.xlsx'
    );
  }

  public create(param: Dict<any>): Promise<any> {
    return this.http.post('/api/user', param);
  }

  public edit(param: Dict<any>): Promise<any> {
    return this.http.put('/api/user', param);
  }

  public getUserInfo(param: Dict<any>): Promise<any> {
    return this.http.get('/api/user/userInfo/' + param);
  }

  public uploadFile(param: Dict<any>): Promise<any> {
    let config: any = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    let queryString: any = {};

    return this.http.post('/api/user/upload', param, queryString, config);
  }
  public changeActive(param: Dict<any>) {
    let url: string = '';
    if (param.type === 'active') {
      url = '/api/user/disabled/' + param.id;
    } else {
      url = '/api/user/enabled/' + param.id;
    }
    return this.http.put(url);
  }

  public batchsync(): Promise<any> {
    return this.http.post('/api/user/batchsync');
  }
  public getSyncNum(): Promise<any> {
    return this.http.get('/api/user/getSyncNum');
  }
  public checkUserAdminStatus():Promise<any>{
    return this.http.get(`/api/user/superadmin/check`)
  }
  public logout(): Promise<any> {
    return this.http.get('/api/admin/logout');
  }

}
