



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';

@Component
export default class NovaContainer extends Vue {
  public showTitle: boolean = false;
  @Prop({ default: '' })
  private title!: string;
  @Prop({ default: true })
  private defaultBgColor!: boolean;

  // i8n
  @Inject(I18nService)
  private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public mounted(): void {
    this.showTitle =
      !!this.title || !!this.$slots.titleButton || !!this.$slots.titleDesc;
  }
}
