import Vue from 'vue';
import NovaTemplate from './index.vue';

function componentsInstall(): void {
  Vue.component('nova-container', NovaTemplate);
}

// tslint:disable-next-line: no-empty
const NovaContainer = () => {};
NovaContainer.install = () => {
  componentsInstall();
};

export default NovaContainer;
