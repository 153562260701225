import {
  ElMessageBoxComponent,
  MessageBoxCloseAction
} from 'element-ui/types/message-box';
import { CloseStatus, IDialogOption } from './models/dialog.interface';
import Vue, { Component, CreateElement, VNode } from 'vue';
import { cloneDeep, forEach, isElement, isFunction, isNumber, isObject, isUndefined } from 'lodash';
import msgboxVue from 'element-ui/packages/message-box/src/main.vue';
import { uuid } from '../utils';

const defaultOptions = {
  dangerouslyUseHTMLString: true,
  lockScroll: false,
  showCancelButton: false,
  showConfirmButton: false,
  closeOnClickModal: false
};
const MessageBoxConstructor = Vue.extend(msgboxVue);
const modelTypes = [
  'modal',
  'showClose',
  'closeOnClickModal',
  'closeOnPressEscape',
  'closeOnHashChange'
];
export class DialogService {
  private nodeMaker!: CreateElement;
  set h(val: CreateElement) {
    this.nodeMaker = val;
  }

  private instanceMap: Map<string, any> = new Map<string, any>();

  private dialogPromiseResolve: Dict<(val: any) => void> = {};
  public open(
    title: string,
    component: Component<any, any, any, any> | string,
    data?: Dict<any>,
    option?: IDialogOption
  ): Promise<{
    action: MessageBoxCloseAction;
    result?: any;
    vueInstance?: Vue & Dict<any>;
  }> {
    return new Promise((resolve: any) => {
      const vElem = this.nodeMaker(component) as any;
      const integedOption = this.getOption(title, vElem, resolve, data, option);
      this.dialogPromiseResolve[integedOption.key] = resolve;
      this.createBox(integedOption);
    });
  }

  public close(
    action: string = CloseStatus.close,
    result?: any,
    key?: string
  ): void {
    let vueInstance: any;
    if (key) {
      vueInstance = this.instanceMap.get(key);
      if (vueInstance) {
        vueInstance.close();
        if (vueInstance.$destory) {
          vueInstance.$destory();
        }
        vueInstance.$el.remove();
      }
    } else {
      this.instanceMap.forEach((e: any) => {
        e.close();
        if (e.$destory) {
          e.$destory();
        }
        e.$el.remove();
      });
    }
    if (key) {
      if (isFunction(this.dialogPromiseResolve[key])) {
        (this.dialogPromiseResolve[key] as any)({
          action,
          result,
          vueInstance
        });
      }
    } else {
      forEach(this.dialogPromiseResolve, (item) => {
        if (isFunction(item)) {
          item({
            action,
            result,
            vueInstance
          });
        }
      });
    }
  }

  private getOption(
    title: string,
    vElem: VNode,
    resolve: (res: {
      action: MessageBoxCloseAction;
      result?: any;
      vueInstance?: Vue & Dict<any>;
    }) => void,
    data?: Dict<any>,
    option: IDialogOption = {}
  ): Dict<any> {
    let ctorPrototype;
    if (vElem.componentOptions) {
      ctorPrototype = vElem.componentOptions.Ctor.prototype;
      ctorPrototype.data = cloneDeep(data);
      ctorPrototype.$key = option.key = option.key || uuid();
    }
    return {
      ...defaultOptions,
      title,
      message: vElem,
      ctorPrototype,
      callback: (
        action: MessageBoxCloseAction,
        vueInstance: ElMessageBoxComponent
      ) => {
        vueInstance.$el.remove();
        resolve({
          action,
          vueInstance
        });
      },
      ...option
    };
  }

  private installDialogBehavior(instance: any): void {
    instance.show = () => {
      instance.$el.style.display = 'flex';
    };
    instance.hide = () => {
      instance.$el.style.display = 'none';
    };
  }

  private createBox(options: IDialogOption & any): ElMessageBoxComponent {
    const instance: any = new MessageBoxConstructor({
      el: document.createElement('div')
    });
    options.ctorPrototype.$dialogInstance = instance;
    setTimeout(() => {
      this.installDialogBehavior(instance);
      const wrapperElement = instance.$el as HTMLDivElement;
      wrapperElement.setAttribute('dialog-id', options.key);
      wrapperElement.style.display = 'flex';
      (wrapperElement.style as any)['justify-content'] = 'center';
      (wrapperElement.style as any)['align-items'] = 'center';
      const msgBoxEl = wrapperElement.querySelector(
        'div.el-message-box'
      ) as HTMLElement;
      const msgBoxBtn = wrapperElement.querySelector(
        'div.el-message-box__btns'
      ) as HTMLElement;
      const msgBoxContentEl = msgBoxEl.querySelector(
        'div.el-message-box__content'
      ) as HTMLElement;
      const msgBoxContainerEl = msgBoxEl.querySelector(
        'div.el-message-box__container'
      ) as HTMLElement;
      const msgBoxMessageEl = msgBoxContainerEl.querySelector(
        'div.el-message-box__message'
      ) as HTMLElement;
      const maxHeight = window.innerHeight * 0.9;
      const maxWidth = window.innerWidth * 0.85;

      const boxRect = msgBoxEl.getBoundingClientRect();
      if (options.width === 'auto' && boxRect.width >= maxWidth) {
        options.width = maxWidth;
      }
      if (options.height === 'auto' && boxRect.height >= maxHeight) {
        options.height = maxHeight;
      }
      if (isElement(msgBoxEl)) {
        const width = (options && options.width) || maxWidth;
        msgBoxEl.style.width =
          width + (isNumber(width) && !isUndefined(width) ? 'px' : '');
        const height = (options && options.height) || maxHeight;
        msgBoxEl.style.height =
          height + (isNumber(height) && !isUndefined(height) ? 'px' : '');
        (msgBoxEl.style as any)['max-height'] = maxHeight + 'px';
        (msgBoxEl.style as any)['max-width'] = maxWidth + 'px';
        msgBoxEl.style.display = 'flex';
        (msgBoxEl.style as any)['flex-direction'] = 'column';
        (msgBoxEl.style as any)['padding-bottom'] = 0;
        (msgBoxBtn.style as any).padding = 0;
        if (isElement(msgBoxContentEl)) {
          (msgBoxContentEl.style as any).flex = '1';
          (msgBoxContentEl.style as any).overflow = 'auto';
          (msgBoxContentEl.style as any).display = 'flex';
          (msgBoxContentEl.style as any)['flex-direction'] = 'column';
          if (isElement(msgBoxContainerEl)) {
            (msgBoxContainerEl.style as any).height = '100%';
            if (isElement(msgBoxMessageEl)) {
              (msgBoxMessageEl.style as any).height = '100%';
              (msgBoxMessageEl.style as any)['overflow-y'] = 'auto';
            }
          }
        }
      }
    });
    if (!instance.visible || instance.closeTimer) {
      for (let prop in options) {
        if (options.hasOwnProperty(prop)) {
          instance[prop] = options[prop];
        }
      }
      if (isObject(instance.message)) {
        instance.$slots.default = [instance.message];
        instance.message = null;
      } else {
        delete instance.$slots.default;
      }
      modelTypes.forEach((prop: any) => {
        if (instance[prop] === undefined) {
          instance[prop] = true;
        }
      });
      document.body.appendChild(instance.$el);
    }
    Vue.nextTick(() => {
      instance.visible = true;
      this.instanceMap.set(options.key, instance);
    });

    return instance;
  }
}
