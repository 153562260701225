












































































































import { Component, Vue, Prop, Ref, Emit, Watch } from 'vue-property-decorator';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';
import { ISearchConfig } from './index.d';
import { ElForm } from 'element-ui/types/form';
import { isArray, assign } from 'lodash';

@Component
export default class NovaContainer extends Vue {
  public formData: Dict<any> = {};
  @Prop({ default: () => ({}) })
  public form!: Dict<any>;
  @Ref('form') public formRef!: ElForm;
  @Prop({ required: true })
  public searchConfig!: ISearchConfig[];

  public formRows: ISearchConfig[][] = [];

  @Inject(I18nService) private i18nSvc!: I18nService;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  @Watch('form', { deep: true, immediate: false })
  public formChangeHandler(val: Dict<any>): void {
    this.formData = val;
  }

  @Watch('searchConfig')
  public searchConfigChange(val: ISearchConfig[]): void {
    const len = val.length;
    const n = 4; // 假设每行显示4个
    const lineNum = len % 4 === 0 ? len / 4 : Math.floor(len / 4 + 1);
    this.formRows = [];
    for (let i = 0; i < lineNum; i++) {
      // slice() 方法返回一个从开始到结束（不包括结束）选择的数组的一部分浅拷贝到一个新数组对象。且原始数组不会被修改。
      let temp = val.slice(i * n, i * n + n);
      this.formRows.push(temp);
    }
    // console.log(this.formRows[1][0].subclass);
    // console.log(this.formData);
    // console.log(this.formRows[1]);
  }

  @Emit('searchchange')
  public handleSearch(): Dict<any> {
    return this.getClearUnassignField();
  }

  @Emit('selectChange')
  public handleChange(): Dict<any> {
    return this.getClearUnassignField();
  }

  private getClearUnassignField(): Dict<any> {
    const result = assign({}, this.formData);
    for (const index in result) {
      if (
        result[index] === '' ||
        (isArray(result[index]) && result[index].length === 0)
      ) {
        delete result[index];
      }
    }
    return result;
  }
  private mounted() {
    // console.log(this.searchConfig)
    this.searchConfigChange(this.searchConfig);
  }
}
