import { Role } from '@/model/schema';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import RouterContainer from './router-container.vue';
import Home from '../views/home/home.vue';


Vue.use(VueRouter);

const routes: RouteConfig[] = [
  // {
  //   path: '/',
  //   name: 'toBHome',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')，
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '',
    redirect: 'home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/manager',
    name: 'Manager', // 管理员管理
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/manager/manager.vue'),
    // component: Manager,
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '管理员管理',
      titleEn: 'Admin Management'
    }
  },
  {
    path: '/consent',
    name: 'Consent', // 隐私协议管理
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/consent/consent.vue'),
    // component: ConsentManager,
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '平台隐私协议',
      titleEn: 'Consent Management'
    }
  },
  {
    path: '/staffdataupload',
    name: 'staffdataupload', // 用户数据上传
    component: () =>
      import(
        /* webpackChunkName: "user" */ '../views/backgroundmanage/staffdataupload.vue'
      ),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '员工数据上传',
      titleEn: 'User data upload'
    }
  },
  {
    path: '/documentManagement',
    name: '文档管理',
    component: () => import('../views/libraryManagement/documentManagement.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '文档管理',
      titleEn: 'documentManagement'
    }
  },
  {
    path: '/addDocument',
    name: '文档配置',
    component: () => import('../views/libraryManagement/addDocument.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '文档配置',
      titleEn: 'addDocument'
    }
  },
  {
    path: '/sensitivewords',
    name: 'sensitivewords',
    component: () => import('../views/sensitivewords/sensitivewords.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '敏感词屏蔽',
      titleEn: 'Sensitive Words shield'
    }
  },
  {
    path: '/operationhistory',
    name: 'operationhistory', // 用户数据上传
    component: () =>
      import('../views/backgroundmanage/operationhistory/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '操作历史查询',
      titleEn: 'User data upload'
    }
  },
  {
    path: '/usergroup',
    name: 'usergroup', // 用户分组
    component: () =>
      import(
        /* webpackChunkName: "userGroup" */ '../views/userGroup/userGroup.vue'
      ),
    // component: KeywordAnalysis,
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '用户分组',
      titleEn: 'User Group'
    }
  },
  {
    path: '/addresslist',
    name: 'addresslist',
    component: () => import('../views/addresslist/addressList.vue'),
    // component: KeywordAnalysis,
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '通讯录',
      titleEn: 'Address List'
    }
  },
  {
    path: '/hrcodelog',
    name: 'hrcodelog',
    component: () => import('../views/hrcodelog/hrcodelog.vue'),
    // component: KeywordAnalysis,
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: 'hrcode查询记录',
      titleEn: 'Address List'
    }
  },
  {
    path: '/pharmafield',
    name: 'pharmafield',
    component: () => import('../views/pharmafieldlog/pharmafield.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: 'Pharma Field Force 同步',
      titleEn: 'Pharma Field Force Org Log'
    }
  },
  {
    path: '/oncologyfield',
    name: 'oncologyfield',
    component: () => import('../views/oncologyfieldlog/oncologyfield.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: 'Oconlogy Field Force 同步',
      titleEn: 'Oncology Field Force Org Log'
    }
  },
  {
    path: '/chatrecordcheck',
    name: 'chatrecordcheck',
    component: () => import('../views/chatrecordcheck/chatrecordcheck.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '聊天记录检查',
      titleEn: 'Chat record check'
    }
  },
  {
    path: '/externalcontacts',
    name: 'externalcontacts',
    component: () => import('../views/externalcontacts/externalcontacts.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '外部联系人',
      titleEn: 'External contacts'
    }
  },
  // {
  //   path: '/unauthorizedExternalcontacts',
  //   name: 'unauthorizedExternalcontacts',
  //   component: () => import('../views/unauthorizedexternalcontacts/unauthorizedexternalcontacts.vue'),
  //   meta: {
  //     roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
  //     auth: true,
  //     title: '未验证外部联系人',
  //     titleEn: 'unauthoried External contacts'
  //   }
  // },
  {
    path: '/departureinheritanced',
    name: 'departureinheritanced',
    component: () => import('../views/departureinheritanced/departureinheritanced.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '离职继承',
      titleEn: 'Departure inheritanced'
    }
  },
  {
    path: '/wechatMenu',
    name: '微信菜单',
    component: () => import('../views/wechatMenu/wechatMenu.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '微信菜单',
      titleEn: 'wechatMenu'
    }
  },
  {
    path: '/wechatReply',
    name: '自动回复',
    component: () => import('../views/wechatReply/wechatReply.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '自动回复',
      titleEn: 'wechatReply'
    }
  },
  {
    path: '/wechatImageText',
    name: '图文管理',
    component: () => import('../views/wechatImageText/wechatImageText.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '图文管理',
      titleEn: 'wechatImageText'
    }
  },

  {
    path: '/addImageText',
    name: '创建图文',
    component: () => import('../views/wechatImageText/addImageText.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '创建图文',
      titleEn: 'addImageText'
    }
  },
  {
    path: '/imageTextClassify',
    name: '图文分类',
    component: () => import('../views/wechatImageText/imageTextClassify.vue'),
    meta: {
      roles: [],
      auth: true,
      title: '图文分类',
      titleEn: 'imageTextClassify'
    }
  },
  {
    path: '/wechatPicture',
    name: '图片管理',
    component: () => import('../views/wechatPicture/wechatPicture.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '图片管理',
      titleEn: 'wechatMenu'
    }
  },
  {
    path: '/wechatFile',
    name: '文件管理',
    component: () => import('../views/wechatFile/wechatFile.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '文件管理',
      titleEn: 'wechatMenu'
    }
  },
  {
    path: '/wechatAudio',
    name: '音频管理',
    component: () => import('../views/wechatAudio/wechatAudio.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '音频管理',
      titleEn: 'wechatMenu'
    }
  },
  {
    path: '/wechatVideo',
    name: '视频管理',
    component: () => import('../views/wechatVideo/wechatVideo.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '视频管理',
      titleEn: 'wechatMenu'
    }
  },
  {
    path: '/advertPage',
    name: '应用开屏列表',
    component: () => import('../views/advertPage/advertPage.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '应用开屏列表',
      titleEn: 'advertPage'
    }
  },
  {
    path: '/advertApplication',
    name: '应用开屏',
    component: () => import('../views/advertPage/advertApplication.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '应用开屏',
      titleEn: 'advertPage'
    }
  },
  {
    path: '/permissionSon',
    name: 'permissionSon', // 权限设置
    component: () => import('../views/channel/permissions/apppermissions.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '应用权限',
      titleEn: 'permissionSon'
    }
  },
  {
    path: '/peopleInformation',
    name: '人员信息',
    component: () => import('../views/bonus/peopleInformation.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '人员信息',
      titleEn: 'peopleInformation'
    }
  },
  {
    path: '/organization',
    name: '人员信息',
    component: () => import('../views/bonus/organization.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '组织架构',
      titleEn: 'organization'
    }
  },
  {
    path: '/productInformation',
    name: '产品信息',
    component: () => import('../views/bonus/productInformation.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '产品信息',
      titleEn: 'productInformation'
    }
  },
  {
    path: '/productPrice',
    name: '产品价格表',
    component: () => import('../views/bonus/productPrice.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '产品价格表',
      titleEn: 'productPrice'
    }
  },
  {
    path: '/correspondingPeriod',
    name: '指标&同期销售',
    component: () => import('../views/bonus/correspondingPeriod.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '指标&同期销售',
      titleEn: 'correspondingPeriod'
    }
  },
  {
    path: '/growthBonus',
    name: '增长奖参数',
    component: () => import('../views/bonus/growthBonus.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '增长奖参数',
      titleEn: 'growthBonus'
    }
  },
  {
    path: '/exportLog',
    name: '导入日志列表',
    component: () => import('../views/bonus/exportLog.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '导入日志列表',
      titleEn: 'exportLog'
    }
  },
  {
    path: '/chatBook',
    name: '企微通讯录',
    component: () => import('../views/chatBook/chatBook.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '企微通讯录',
      titleEn: 'chatBook'
    }
  },
  {
    path: '/channel',
    name: 'Channel', // 标签管理
    component: RouterContainer,
    // component: Tag,
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '标签管理',
      titleEn: 'User Group Management'
    },
    children: [
      {
        path: '',
        name: 'ChannelList', // 专栏
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/channel/channel.vue'
          ),
        // component: Channel,
        meta: {
          roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
          auth: true,
          title: '应用管理',
          titleEn: 'Applacation Management'
        }
      },
      {
        path: 'permissions',
        name: 'Permissions', // 权限设置
        component: () => import('../views/channel/permissions/permissions.vue'),
        meta: {
          roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
          auth: true,
          title: '权限设置',
          titleEn: 'Permissions'
        }
      },

      // {
      //   path: 'channelDetail',
      //   name: 'ChannelDetail', // 专栏
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ '../views/channel/channelDetailTab.vue'
      //     ),
      //   // component: ChannelDetail,
      //   meta: {
      //     roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      //     auth: true,
      //     title: '专栏管理',
      //     titleEn: 'Channel Management'
      //   },
      //   children: [
      //     {
      //       path: 'menu',
      //       name: 'MenuMain', // 专栏
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "about" */ '../views/channel/menu/menuMain.vue'
      //         ),
      //       // component: MenuMain,
      //       meta: {
      //         roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      //         auth: true,
      //         title: '专栏管理',
      //         titleEn: 'Channel Management'
      //       }
      //     },
      //     {
      //       path: 'reply',
      //       name: 'ReplyMain', // 专栏
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "about" */ '../views/channel/reply/replyMain.vue'
      //         ),
      //       // component: ReplyMain,
      //       meta: {
      //         roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      //         auth: true,
      //         title: '专栏管理',
      //         titleEn: 'Channel Management'
      //       }
      //     },
      //     {
      //       path: 'material',
      //       name: 'MaterialMain', // 专栏
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "about" */ '../components/material-selector/material/materialMain.vue'
      //         ),
      //       // component: MaterialMain,
      //       meta: {
      //         roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      //         auth: true,
      //         title: '专栏管理',
      //         titleEn: 'Channel Management'
      //       }
      //     },
      //     {
      //       path: 'message',
      //       name: 'MessageMain', // 专栏
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "about" */ '../views/channel/message/messageMain.vue'
      //         ),
      //       // component: MessageMain,
      //       meta: {
      //         roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      //         auth: true,
      //         title: '专栏管理',
      //         titleEn: 'Channel Management'
      //       }
      //     },
      //     {
      //       path: 'statistics',
      //       name: 'StatisticsMain', // 专栏
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "about" */ '../views/channel/statistics/statisticsMain.vue'
      //         ),
      //       // component: StatisticsMain,
      //       meta: {
      //         roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      //         auth: true,
      //         title: '专栏管理',
      //         titleEn: 'Channel Management'
      //       },
      //       children: [
      //         {
      //           path: 'pictureAnalysis',
      //           name: 'PictureAnalysis', // 专栏
      //           component: () =>
      //             import(
      //               /* webpackChunkName: "about" */ '../views/channel/statistics/pictureAnalysis.vue'
      //             ),
      //           // component: PictureAnalysis,
      //           meta: {
      //             roles: [
      //               Role.SUPER_ADMIN,
      //               Role.DIGITAL_ADMIN,
      //               Role.CHANNEL_ADMIN
      //             ],
      //             auth: true,
      //             title: '专栏管理',
      //             titleEn: 'Channel Management'
      //           }
      //         },
      //         {
      //           path: 'userAnalysis',
      //           name: 'UserAnalysis', // 专栏
      //           component: () =>
      //             import(
      //               /* webpackChunkName: "about" */ '../views/channel/statistics/userAnalysis.vue'
      //             ),
      //           // component: UserAnalysis,
      //           meta: {
      //             roles: [
      //               Role.SUPER_ADMIN,
      //               Role.DIGITAL_ADMIN,
      //               Role.CHANNEL_ADMIN
      //             ],
      //             auth: true,
      //             title: '专栏管理',
      //             titleEn: 'Channel Management'
      //           }
      //         },
      //         {
      //           path: 'applicationAnalysis',
      //           name: 'ApplicationAnalysis', // 专栏
      //           component: () =>
      //             import(
      //               /* webpackChunkName: "about" */ '../views/channel/statistics/applicationAnalysis.vue'
      //             ),
      //           // component: ApplicationAnalysis,
      //           meta: {
      //             roles: [
      //               Role.SUPER_ADMIN,
      //               Role.DIGITAL_ADMIN,
      //               Role.CHANNEL_ADMIN
      //             ],
      //             auth: true,
      //             title: '专栏管理',
      //             titleEn: 'Channel Management'
      //           }
      //         },
      //         {
      //           path: 'keywordAnalysis',
      //           name: 'KeywordAnalysis', // 专栏
      //           component: () =>
      //             import(
      //               /* webpackChunkName: "about" */ '../views/channel/statistics/keywordAnalysis.vue'
      //             ),
      //           // component: KeywordAnalysis,
      //           meta: {
      //             roles: [
      //               Role.SUPER_ADMIN,
      //               Role.DIGITAL_ADMIN,
      //               Role.CHANNEL_ADMIN
      //             ],
      //             auth: true,
      //             title: '专栏管理',
      //             titleEn: 'Channel Management'
      //           }
      //         },
      //         {
      //           path: 'historyAnalysis',
      //           name: 'HistoryAnalysis', // 专栏
      //           component: () =>
      //             import(
      //               /* webpackChunkName: "about" */ '../views/channel/statistics/historyAnalysis.vue'
      //             ),
      //           // component: HistoryAnalysis,
      //           meta: {
      //             roles: [
      //               Role.SUPER_ADMIN,
      //               Role.DIGITAL_ADMIN,
      //               Role.CHANNEL_ADMIN
      //             ],
      //             auth: true,
      //             title: '专栏管理',
      //             titleEn: 'Channel Management'
      //           }
      //         }
      //       ]
      //     }
      //   ]
      // }
    ]
  },
  // {
  //   path: '/keywordAdd',
  //   name: 'keywordAdd', // 专栏
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ '../views/channel/reply/keywordReplyAdd.vue'
  //     ),
  //   // component: keywordAdd,
  //   meta: {
  //     roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
  //     auth: true,
  //     title: '专栏管理',
  //     titleEn: 'Channel Management'
  //   }
  // },
  // {
  //   path: '/statistics',
  //   name: 'StatisticsMain', // 专栏
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ '../views/channel/statistics/statisticsMain.vue'
  //     ),
  //   // component: StatisticsMain,
  //   meta: {
  //     roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
  //     auth: true,
  //     title: '专栏管理',
  //     titleEn: 'Channel Management'
  //   },
  //   children: [
  //     {
  //       path: 'pictureAnalysis',
  //       name: 'PictureAnalysis', // 专栏
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "about" */ '../views/channel/statistics/pictureAnalysis.vue'
  //         ),
  //       // component: PictureAnalysis,
  //       meta: {
  //         roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
  //         auth: true,
  //         title: '专栏管理',
  //         titleEn: 'Channel Management'
  //       }
  //     },
  //     {
  //       path: 'userAnalysis',
  //       name: 'UserAnalysis', // 专栏
  //       // component: () => import(/* webpackChunkName: "about" */ '../views/channel/statistics/userAnalysis.vue')
  //       // component: UserAnalysis,
  //       meta: {
  //         roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
  //         auth: true,
  //         title: '专栏管理',
  //         titleEn: 'Channel Management'
  //       }
  //     },
  //     {
  //       path: 'applicationAnalysis',
  //       name: 'ApplicationAnalysis', // 专栏
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "about" */ '../views/channel/statistics/applicationAnalysis.vue'
  //         ),
  //       // component: ApplicationAnalysis,
  //       meta: {
  //         roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
  //         auth: true,
  //         title: '专栏管理',
  //         titleEn: 'Channel Management'
  //       }
  //     },
  //     {
  //       path: 'keywordAnalysis',
  //       name: 'KeywordAnalysis', // 专栏
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "about" */ '../views/channel/statistics/keywordAnalysis.vue'
  //         ),
  //       // component: KeywordAnalysis,
  //       meta: {
  //         roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
  //         auth: true,
  //         title: '专栏管理',
  //         titleEn: 'Channel Management'
  //       }
  //     },
  //     {
  //       path: 'historyAnalysis',
  //       name: 'HistoryAnalysis', // 专栏
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "about" */ '../views/channel/statistics/historyAnalysis.vue'
  //         ),
  //       // component: HistoryAnalysis,
  //       meta: {
  //         roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
  //         auth: true,
  //         title: '专栏管理',
  //         titleEn: 'Channel Management'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/simulate',
  //   name: 'Simulate', // 专栏
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/simulate/list.vue'),
  //   // component: Simulate,
  //   meta: {
  //     roles: [Role.SUPER_ADMIN],
  //     auth: true,
  //     title: '模拟用户',
  //     titleEn: 'Simulate Management'
  //   }
  // },
  {
    path: '/unauth',
    name: 'AuthErrorPage', // 权限错误
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/authErrorPage.vue'),
    // component: AuthErrorPage,
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: false,
      title: '权限错误',
      titleEn: 'Error Page'
    }
  }
  // {
  //   path: '/CreateNewImageText',
  //   name: 'CreateNewImageText', // 创建新图文
  //   // component: () => import(/* webpackChunkName: "about" */ '../views/event/event.vue')
  //   component: () => import('../views/channel/material/material-items/createNewImageText.vue'),
  //   // component: CreateNewImageText,
  //   meta: {
  //     roles: ['1,2,3'],
  //     auth: true,
  //     title: '创建新图文',
  //     titleEn: 'Create New Image Text'
  //   }
  // },
  // {
  //   path: '/EditNewImageText',
  //   name: 'EditNewImageText', // 创建新图文
  //   component: () => import('../views/channel/material/material-items/editNewImageText.vue'),
  //   meta: {
  //     roles: ['1,2,3'],
  //     auth: true,
  //     title: '编辑图文',
  //     titleEn: 'Edit New Image Text'
  //   }
  // },
  // {
  //   path: '/SortImageText',
  //   name: 'SortImageText', // 图文分类
  //   component: () => import('../views/channel/message/sortImageText.vue'),
  //   meta: {
  //     roles: ['1,2,3'],
  //     auth: true,
  //     title: '图文分类',
  //     titleEn: 'Sort Image Text'
  //   }
  // }
  , {
    // 群发消息管理
    path: '/sendMessagesGroupSet',
    name: 'sendMessagesGroupSet', // 群发消息分组设置
    component: () => import('../views/massmessaging/groupSet.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '群发消息分组设置',
      titleEn: 'Send Message Set'
    }
  },
  {
    // 群发消息管理 创建分组
    path: '/greatGroup',
    name: 'greatGroup',
    component: () => import('../views/massmessaging/massageAdd/creatGroup.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '分组设置',
      titleEn: 'Send Message Set'
    }
  },
  {
    // 群发消息管理
    path: '/groupMessagesSpecial',
    name: 'groupMessagesSpecial', // 专栏群发消息
    component: () => import('../views/massmessaging/groupMessages.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '专栏群发消息',
      titleEn: 'Send Message'
    }
  }, {
    // 群发消息管理 应用群发消息
    path: '/groupMessagesChannel',
    name: 'groupMessagesChannel', //应用群发消息
    component: () => import('../views/massmessaging/application/applicationMassage.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '应用群发消息',
      titleEn: 'Application Message'
    }
  },
  {
    path: '/creatRoster',
    name: 'creatRoster', // 专栏创建预览名单
    component: () =>
      import(
        '../views/massmessaging/massageAdd/creatRoster.vue'
      ),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '创建预览名单',
      titleEn: 'Creat Roster'
    }
  }
  ,
  {
    path: '/creatMessage',
    name: 'creatMessage', // 专栏创建群发消息
    component: () =>
      import(
        '../views/massmessaging/massageAdd/creatMessage.vue'
      ),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '创建群发消息',
      titleEn: 'Creat Message'
    }
  }
  , {
    //  应用群发消息 创建预览名单
    path: '/channelRoster',
    name: 'channelRoster',
    component: () => import('../views/massmessaging/application/creatRoster.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '创建预览名单',
      titleEn: 'Application Message'
    }
  }, {
    //  应用群发消息 创建群发消息
    path: '/channelMessage',
    name: 'channelMessage',
    component: () => import('../views/massmessaging/application/creatMessage.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '创建群发消息',
      titleEn: 'Application Message'
    }
  }, {
    //  数据分析  图文分析
    path: '/imgTextStatistics',
    name: 'imgTextStatistics',
    component: () => import('../views/statistics/imgTextStatistics.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '图文分析(平台)',
      titleEn: 'Img&Text Statistics'
    }
  }, {
    //  数据分析 新 图文分析
    path: '/newImgText',
    name: 'newImgText',
    component: () => import('../views/statistics/newImgText.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '图文分析',
      titleEn: 'Img&Text Statistics'
    }
  }
  , {
    //  数据分析  用户分析
    path: '/personStatistics',
    name: 'personStatistics',
    component: () => import('../views/statistics/personStatistics.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '用户分析',
      titleEn: 'User Statistics'
    }
  }, {
    //  数据分析 专栏分析
    path: '/columnStatistics',
    name: 'columnStatistics',
    component: () => import('../views/statistics/columnStatistics.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '专栏分析',
      titleEn: 'User Statistics'
    }
  }, {
    //  数据分析 关键字搜索分析
    path: '/keywordStatistics',
    name: 'keywordStatistics',
    component: () => import('../views/statistics/keywordStatistics.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: ' 关键字搜索分析',
      titleEn: 'Key Word Search Statistics'
    }
  }
  , {
    //  数据分析 历史消息搜索分析
    path: '/historicalStatistics',
    name: 'historicalStatistics',
    component: () => import('../views/statistics/historicalStatistics.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '历史消息搜索分析',
      titleEn: 'Historical Statistics'
    }
  }, {
    //  数据分析 历史消息搜索分析
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import('../views/backgroundmanage/paddword/reset.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '小诺密码服务',
      titleEn: 'Password Service'
    }
  },
  {
    path: '/bonusestimate',
    name: '奖金预估报表',
    component: () => import('../views/bonus/bonusestimate.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '奖金预估报表',
      titleEn: 'bonusestimate'
    }
  },
  {
    path: '/dmReportForms',
    name: 'DM奖金预估报表',
    component: () => import('../views/bonus/dmReportForms.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: 'DM奖金预估报表',
      titleEn: 'DmReportForms'
    }
  },
  {
    path: '/rmReportForms',
    name: 'RM奖金预估报表',
    component: () => import('../views/bonus/rmReportForms.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: 'RM奖金预估报表',
      titleEn: 'RmReportForms'
    }
  },
  {
    path: '/adReportForms',
    name: 'AD奖金预估报表',
    component: () => import('../views/bonus/adReportForms.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: 'AD奖金预估报表',
      titleEn: 'ADReportForms'
    }
  },
  {
    path: '/reachpayment',
    name: '达成率&支付比例',
    component: () => import('../views/bonus/reachpayment.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '达成率&支付比例',
      titleEn: 'reachpayment'
    }
  }, {
    path: '/lastyearachievementrate',
    name: '去年全年达成率',
    component: () => import('../views/bonus/lastyearachievementrate.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '去年全年达成率',
      titleEn: 'lastyearachievementrate'
    }
  }, {
    path: '/gearrange',
    name: '档位区间',
    component: () => import('../views/bonus/gearrange.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '档位区间',
      titleEn: 'gearrange'
    }
  },
  {
    path: '/geaconfigurationr',
    name: '档位说明',
    component: () => import('../views/bonus/gearraInfo.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '档位说明',
      titleEn: 'geaconfigurationr'
    }
  },
  {
    path: '/pmconfiguration',
    name: '管理员模拟权限配置',
    component: () => import('../views/bonus/pmconfiguration.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '管理员模拟权限配置',
      titleEn: 'pmconfiguration'
    }
  },
  {
    path: '/achievementawardbase',
    name: '达成奖基数',
    component: () => import('../views/bonus/achievementawardbase.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '达成奖基数',
      titleEn: 'achievementawardbase'
    }
  },
  {
    path: '/mimanage',
    name: '医学问询管理',
    component: () => import('../views/backgroundmanage/medicalinquiry/mimanage.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '医学问询管理',
      titleEn: 'MI-mimanage'
    }
  },
  {
    path: '/swiftlog',
    name: 'SWIFT日志',
    component: () => import('../views/backgroundmanage/swift/log.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: 'SWIFT日志',
      titleEn: 'SWIFT'
    }
  },
  {
    path: '/rankingrenew',
    name: '奖金排行更新',
    component: () => import('../views/bonus/rankingrenew.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '奖金排行更新',
      titleEn: 'rankingrenew'
    }
  },
  {
    path: '/rankingList',
    name: '非销售奖金排行更新',
    component: () => import('../views/bonus/rankingList.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '非销售奖金排行更新',
      titleEn: 'rankingList'
    }
  },
  {
    path: '/noticesbonus',
    name: '公告提示',
    component: () => import('../views/bonus/noticesbonus.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '公告提示',
      titleEn: 'pmconfiguration'
    }
  },
  {
    path: '/configMsg',
    name: '消息配置',
    component: () => import('../views/bonus/configMsg.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '消息配置',
      titleEn: 'configMsg'
    }
  },
  {
    path: '/adList',
    name: 'AD信息',
    component: () => import('../views/bonus/adList.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: 'AD信息',
      titleEn: 'adList'
    }
  },
  {
    path: '/awardConfig',
    name: '奖项配置',
    component: () => import('../views/bonus/awardConfig.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '奖项配置',
      titleEn: 'awardConfig'
    }
  },

  //
  {
    path: '/productInfoCoefficient',
    name: '特定产品线系数配置',
    component: () => import('../views/bonus/productInfoCoefficient.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN ],
      auth: true,
      title: '特定产品线系数配置',
      titleEn: 'productInfoCoefficient'
    }
  },

  {
    path: '/roleplay',
    name: 'Role Play',
    component: () => import('../views/applicationFunManagement/roleplay.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: 'Role Play',
      titleEn: 'Role Play'
    }
  },
  {
    path: '/addProductMap',
    name: 'addProductMap', //个性化功能管理
    component: () =>
      import(/* webpackChunkName: "productMap" */ '../views/productMap/addProductMap.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '创建产品地图管理',
      titleEn: 'addProductMap'
    }
  },

  {
    path: '/productMap',
    name: 'productMap', //个性化功能管理
    component: () =>
      import(/* webpackChunkName: "productMap" */ '../views/productMap/productMap.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '产品地图管理',
      titleEn: 'productMap'
    }
  },
  {
    path: '/surveyTypeDictionary',
    name: 'surveyTypeDictionary', //问卷类型管理
    component: () =>
      import(/* webpackChunkName: "surveyTypeDictionary" */ '../views/surveyConfig/surveyTypeDictionary.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '远程助手类型管理',
      titleEn: 'surveyTypeDictionary'
    }
  },
  {
    path: '/surveyQuestionnaire',
    name: 'surveyQuestionnaire', //问卷管理
    component: () =>
      import(/* webpackChunkName: "surveyQuestionnaire" */ '../views/surveyConfig/surveyQuestionnaire.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '远程助手管理',
      titleEn: 'surveyQuestionnaire'
    }
  },
  {
    path: '/addSurvey',
    name: 'addSurvey',
    component: () =>
      import(/* webpackChunkName: "addSurvey" */ '../views/surveyConfig/addSurvey.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN],
      auth: true,
      title: '添加远程助手',
      titleEn: 'addSurvey'
    }
  },
  {
    path: '/achievementawardbaseConfig',
    name: '档位品牌指标配置',
    component: () => import('../views/bonus/achievementawardbaseConfig.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '档位品牌指标配置',
      titleEn: 'achievementawardbase'
    }
  },
  {
    path: '/video_list',
    name: '视频列表',
    component: () => import('../views/wecom/videoList/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN],
      auth: true,
      title: '视频列表',
      titleEn: 'Wecom Video List'
    },
  },
  {
    path: '/wecomVideo/add',
    name: 'videoAdd',
    component: () => import('../views/wecom/video/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN],
      auth: true,
      title: '新增视频',
      titleEn: 'Wecom Video Add'
    },
  },
  {
    path: '/wecomVideo/:videoId/edit',
    name: 'videoEdit',
    component: () => import('../views/wecom/video/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN],
      auth: true,
      title: '编辑视频',
      titleEn: 'Wecom Video Edit'
    },
  },
  {
    path: '/wecomVideo/:videoId',
    name: 'videoDetail',
    component: () => import('../views/wecom/video/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN],
      auth: true,
      title: '视频详情',
      titleEn: 'Wecom Video Detail'
    },
  },
  {
    path: '/video_channel_management',
    name: 'channelManagement',
    component: () => import('@/views/wecom/channelManagement/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN],
      auth: true,
      title: '频道维护管理',
      titleEn: 'Wecom Channel Management'
    },
  },
  {
    path: '/video_statement',
    name: 'videoStatement',
    component: () => import('@/views/wecom/videoStatement/index.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN],
      auth: true,
      title: '行为数据查询',
      titleEn: 'WeCom Behavior Data Query'
    },
  },
  {
    path: '/awardManagement',
    name: '奖项管理',
    component: () => import('../views/bonus/awardManagement.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '奖项管理',
      titleEn: 'awardManagement'
    }
  },
  {
    path: '/addAward',
    name: '新增奖项',
    component: () => import('../views/bonus/compoennts/addAward.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '新增奖项',
      titleEn: 'addAward'
    }
  },
  {
    path: '/awardContent',
    name: '奖项内容管理',
    component: () => import('../views/bonus/awardContent.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '奖项内容管理',
      titleEn: 'awardContent'
    }
  },
  {
    path: '/detailsContent',
    name: '奖项详情内容',
    component: () => import('../views/bonus/detailsContent.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '奖项详情内容',
      titleEn: 'detailsContent'
    }
  },
  {
    path: '/detailsNewContent',
    name: '奖项详情内容',
    component: () => import('../views/bonus/detailsNewContent.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '奖项详情内容',
      titleEn: 'detailsNewContent'
    }
  },
  {
    path: '/addDetailsNewContent',
    name: '奖项详情内容',
    component: () => import('../views/bonus/compoennts/addDetailsNewContent.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '奖项详情内容',
      titleEn: 'addDetailsNewContent'
    }
  },
  {
    path: '/addDetailsContent',
    name: '新增奖项内容',
    component: () => import('../views/bonus/compoennts/addDetailsContent.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '新增奖项内容',
      titleEn: 'addDetailsContent'
    }
  },
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
export default router;
