














import { Component, Vue, Prop } from 'vue-property-decorator';
import { I18nService, Locale } from '@cds/i18n';
import { Inject } from '@cds/common';

@Component
export default class NovaTable extends Vue {
  @Prop({ default: () => [] })
  private config!: any[];

  @Prop({ required: true, default: () => [] })
  private data!: any[];
}
